/* eslint-disable prettier/prettier */

// Libraries
import { mapGetters, mapState } from "vuex"

// Mixins
import taxFunctions from "./taxFunctions.js"
import capitalize from "@/mixins/capitalize.js"

export default {
  mixins: [taxFunctions, capitalize],
  data() {
    return {
      filteredHeaders: [],
      selectedRestaurantId: "",
    }
  },
  methods: {
    addHeader(text, value, description = "", show = true, divider = false, cellClass = "") {
      if (show) {
        this.filteredHeaders.push({ text, value, description, divider, cellClass })
      }
    },
    getHeaders(includeGeneralColumns = true, roleType = "sales", auxiliaryData = {}) {
      this.filteredHeaders = []
      this.selectedRestaurantId = auxiliaryData.selectedRestaurantId

      // General order columns
      this.addHeader("Type", "type")
      this.addHeader("ID", "id")
      this.addHeader("#", "orderNumber", "")
      this.addHeader("Date / Time", "date", "", includeGeneralColumns)
      this.addHeader("Source", "source", "", includeGeneralColumns)
      this.addHeader("Status", "status", "", includeGeneralColumns, includeGeneralColumns)
      this.addHeader("Subtotal", "subtotal", "", roleType !== "restaurant" && includeGeneralColumns)
      this.addHeader("Discounts", "discount", "", roleType !== "restaurant" && includeGeneralColumns)
      this.addHeader("After Discounts", "subtotalWithDiscount", "", roleType === "nextgen" && includeGeneralColumns)
      this.getUniqueTaxes()?.forEach(tax => {
        this.addHeader(tax.name, tax.uniqueId, "", roleType !== "restaurant" && includeGeneralColumns)
      })
      this.addHeader("Total Taxes Paid", "taxes", "Sum of all taxes paid by the customer.", roleType !== "restaurant" && includeGeneralColumns)
      this.addHeader("Tips", "tips", "",  roleType !== "restaurant" && includeGeneralColumns)
      this.addHeader("Service Charge", "serviceCharge", "Total service charge paid by customer.",  roleType !== "restaurant" && includeGeneralColumns)
      this.addHeader("Total Paid", "totalPaid", "Total paid by customer.",  roleType !== "restaurant", !includeGeneralColumns, "font-weight-bold green--text text--darken-1")

      // NextGen columns
      if (roleType === "nextgen") {
        this.addHeader(`Total ${this.paymentProvider} Fee`, "paymentProcessingFee", `Total Payment Processing Fee charged by ${this.paymentProvider}.`, includeGeneralColumns, includeGeneralColumns, "lighttext--text")
        this.addHeader("NextGen Fee", "nextGenFeeSubTotal", `${this.settings.platformFee}% of the total paid before taxes charged to ${this.organizationName}.`, parseFloat(this.getTotal("nextGenFeeSubTotal")) !== parseFloat(this.getTotal("nextGenFee")))
        this.addHeader("NextGen Fee GST", "nextGenFeeGST", `${this.settings.platformFeeGST}% GST on NextGen Fee.`, parseFloat(this.getTotal("nextGenFeeGST")) !== 0)
        this.addHeader("NextGen Fee PST", "nextGenFeePST", `${this.settings.platformFeePST}% PST on NextGen Fee.`, parseFloat(this.getTotal("nextGenFeePST")) !== 0)
        this.addHeader("NextGen Fee Total", "nextGenFee", `${this.settings.platformFee}% of the total paid charged to ${this.organizationName}.`, parseFloat(this.getTotal("nextGenFee")) !== 0)
        this.addHeader(`NextGen Fee ${this.paymentProvider} Fee Portion`, "nextGenFeePaymentProcessingFee") 
        this.addHeader("Total Received by NextGen", "nextGenTotalPayout")
      }

      // Organization columns displayed to organization only
      if (roleType === "org") {
        this.addHeader("Commission Subtotal", "organizationFeeSubTotal", "", parseFloat(this.getTotal("organizationFeeSubTotal")) !== 0)
        this.addHeader("Commission GST", "organizationFeeGST", `${this.settings.organizationFeeGST}% GST on ${this.organizationName} Commission.`, parseFloat(this.getTotal("organizationFeeGST")) !== 0)
        this.addHeader("Commission PST", "organizationFeePST", `${this.settings.organizationFeePST}% PST on ${this.organizationName} Commission.`, parseFloat(this.getTotal("organizationFeePST")) !== 0)
        this.addHeader("Commission Total", "organizationFee", `${this.settings.organizationFee}% of the total paid, collected by ${this.organizationName} and charged to all restaurants.`, parseFloat(this.getTotal("organizationFee")) !== 0)
        this.addHeader(this.organizationName + " Tips", "organizationTip")
        this.addHeader(this.organizationName + " Discounts", "organizationDiscount", "", parseFloat(this.getTotal("organizationDiscount")) !== 0)
        this.addHeader("Platform Fee Subtotal", "platformFeeSubTotal", `${this.settings.platformFee}% of the total paid charged to ${this.organizationName}.`, parseFloat(this.getTotal("platformFeeSubTotal")) !== parseFloat(this.getTotal("platformFee")))
        this.addHeader("Platform Fee GST", "platformFeeGST", `${this.settings.platformFeeGST}% GST on Platform Fee.`, parseFloat(this.getTotal("platformFeeGST")) !== 0)
        this.addHeader("Platform Fee PST", "platformFeePST", `${this.settings.platformFeePST}% PST on Platform Fee.`, parseFloat(this.getTotal("platformFeePST")) !== 0)
        this.addHeader("Platform Fee", "platformFee", `Total Platform Fee.`, parseFloat(this.getTotal("platformFeePST")) !== 0)
        this.addHeader(`Platform Fee ${this.paymentProvider} Fee`, "platformFeePaymentProcessingFee", "", parseFloat(this.getTotal("platformFeePaymentProcessingFee")) !== 0)
        this.addHeader(this.organizationName + ` ${this.paymentProvider} Fee`, "organizationPaymentProcessingFee", "", parseFloat(this.getTotal("organizationPaymentProcessingFee")) !== 0)
        this.addHeader(`${this.paymentProvider} Fee Refund`, "paymentProcessingFeeRefund", `This amount is removed from the ${this.organizationName} ${this.paymentProvider} account to refund the customer.`, parseFloat(this.getTotal("paymentProcessingFeeRefund")) !== 0) 
        this.addHeader("Total Received by " + this.organizationName, "orgTotalReceived", "", parseFloat(this.getTotal("orgTotalReceived")) !== 0, false, "font-weight-bold green--text text--darken-1")
        this.addHeader(this.organizationName + " Discrepancy", "orgTotalReceivedCheck", "Checks if calculations were done correctly. OK if it's off by 1 cent.", parseFloat(this.getTotal("orgTotalReceivedCheck")) !== 0 && this.isNextGenUser) 
        this.addHeader(`Total ${this.paymentProvider} Fee`, "paymentProcessingFee", `Total Payment Processing Fee charged by ${this.paymentProvider}.`, includeGeneralColumns, includeGeneralColumns, "lighttext--text")
        this.addHeader("Total Distributed to All Restaurants", "totalDistributed", "", parseFloat(this.getTotal("totalDistributed")) !== 0)
      }

      // Restaurant columns displayed to restaurant only
      if (roleType === "restaurant") {
        let selectedRestaurant

        if (this.selectedRestaurantId) {
          selectedRestaurant = this.restaurants.find(restaurant => restaurant.id === this.selectedRestaurantId)
        } else {
          selectedRestaurant = this.restaurants[0]
        }

        this.addHeader("Number of Restaurants", "numRestaurants", "", this.isNextGenUser)

        this.addHeader("Square Order ID", "posOrderId" + selectedRestaurant.id, "", false)
        this.addHeader("Subtotal", "subTotal" + selectedRestaurant.id)
        this.addHeader("Discounts", "discount" + selectedRestaurant.id)

        this.taxes
          .filter(tax => {
            return tax.restaurantIds.includes(selectedRestaurant.id)
          })
          .forEach(tax => {
            this.addHeader(tax.name, this.getTaxId(tax, selectedRestaurant), "", parseFloat(this.getTotal(this.getTaxId(tax, selectedRestaurant))) !== 0)
          })

        this.addHeader("All Taxes", "taxes" + selectedRestaurant.id)
        this.addHeader("Tips", "tips" + selectedRestaurant.id)
        this.addHeader("Total Paid", "totalPaid" + selectedRestaurant.id, "", true, false, "font-weight-bold green--text text--darken-1")
        this.addHeader("Commission", "organizationFee" + selectedRestaurant.id, `${this.settings.organizationFee}% of the total paid charged by ${this.organizationName} to ${selectedRestaurant.name}.`, parseFloat(this.getTotal("organizationFee" + selectedRestaurant.id)) !== 0)
        this.addHeader("Commission GST", "organizationFeeGST" + selectedRestaurant.id, `${this.settings.organizationFeeGST}% GST on ${this.organizationName} Commission.`, parseFloat(this.getTotal("organizationFeeGST" + selectedRestaurant.id)) !== 0)
        this.addHeader("Commission PST", "organizationFeePST" + selectedRestaurant.id, `${this.settings.organizationFeePST}% PST on ${this.organizationName} Commission.`, parseFloat(this.getTotal("organizationFeePST" + selectedRestaurant.id)) !== 0)
        this.addHeader(`${this.paymentProvider} Fee`, "paymentProcessingFee" + selectedRestaurant.id)
        this.addHeader("Total Received", "netTotal" + selectedRestaurant.id, "", true, false, "font-weight-bold green--text text--darken-1")
        this.addHeader("Discrepancy", "netTotalCheck" + selectedRestaurant.id, "Checks if calculations were done correctly. OK if it's off by 1 cent.", this.isNextGenUser)
      }

      // Actions column
      this.addHeader("Actions", "actions")
      // this.addHeader("Restaurants", "restaurants")
      // this.addHeader("Order Info", "orderInfo")

      return this.filteredHeaders
    },
    getTotal(column) {
      if (["OrderId", "source", "status", "actions"].includes(column)) {
        return ``
      }
      let total = 0
      this.salesData.forEach(order => {
        total += parseFloat(order[column] ? order[column] : 0)
      })
      return this.formatPrice(total * 100)
    },
  },
  computed: {
    ...mapState(["settings", "taxes"]),
    ...mapGetters(["isNextGenUser"]),
    organizationName() {
      return this.settings.organizationNameReport ? this.settings.organizationNameReport : this.settings.organizationName ? this.settings.organizationName : "Organization"
    },
    paymentProvider() {
      return this.settings.paymentProvider ? this.capitalize(this.settings.paymentProvider) : "Payment Provider"
    },
  },
}
