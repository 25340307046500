<template>
  <div>
    <page-component
      class="page-container"
      :pageTitle="`${title} Report`"
      :headers="headers"
      :items="salesData"
      :fixedHeader="true"
      :sumRowFunction="getTotal"
      sortBy="orderNumber"
      itemKey="index"
      :loadingData="loading"
    >
      <template v-slot:buttons>
        <vue-json-to-csv
          :jsonData="salesData"
          :labels="csvHeaders"
          :csvTitle="`${title} Report`"
        >
          <v-btn class="mx-2" color="primary">
            <v-icon class="mr-2 ml-n1">mdi-download</v-icon>
            Download as CSV
          </v-btn>
        </vue-json-to-csv>
      </template>
      <template v-slot:filters[0]>
        <custom-date-range-filter
          dense
          show-times
          :initial-dates="dates"
          @change="datesRangeUpdated"
        />
      </template>
      <template v-if="restaurants && restaurants.length > 1" v-slot:filters[1]>
        <custom-select
          v-model="selectedRestaurantIds"
          :items="restaurants"
          item-value="id"
          item-text="name"
          label="Restaurants"
          :disabled="type === 'restaurant'"
          multiple
        />
      </template>
      <template v-slot:filters[2]>
        <custom-select
          v-model="orderStatusFilter"
          :items="orderStatusOptions"
          label="Order Status"
          multiple
        />
      </template>
      <template v-slot:filters[3]>
        <custom-select
          v-model="orderSourceFilter"
          :items="orderSourceOptions"
          label="Order Source"
          multiple
        />
      </template>
      <template v-slot:filters[4]>
        <custom-select
          v-model="typeFilter"
          :items="typeOptions"
          label="Type"
          multiple
        />
      </template>
      <template v-if="type !== 'restaurant'" v-slot:filters[5]>
        <v-checkbox
          v-model="includeGeneralColumns"
          label="Include order detail columns"
          class="my-1 ml-2"
          hide-details
        ></v-checkbox>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span class="text-no-wrap">
          {{ getDateFormatted(item.date) }}
          <br />
          <span style="color: var(--v-lighttext-base)">
            {{ getTimeFormatted(item.date) }}
          </span>
        </span>
        <span v-if="item.type === 'Refund'" class="text-no-wrap">
          <br />
          Refunded:
          <br />
          {{ getDateFormatted(item.refundDate) }}
          <br />
          <span style="color: var(--v-lighttext-base)">
            {{ getTimeFormatted(item.refundDate) }}
          </span>
        </span>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <span class="text-no-wrap">
          {{ item.id }}
          <span v-if="item.type === 'Refund'">
            <br />
            <span style="color: var(--v-lighttext-base)">
              Refund ID: {{ item.refundId }}
            </span>
          </span>
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          class="mr-2"
          open-on-click
          offset-x
          offset-y
          bottom
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text small icon v-bind="attrs" v-on="on">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <order-details :order="item.fullOrder" style="max-height: 500px" />
        </v-menu>
        <v-menu
          v-if="isNextGenUser"
          class="mr-2"
          open-on-click
          offset-x
          offset-y
          bottom
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text small icon v-bind="attrs" v-on="on">
              <v-icon>mdi-code-tags</v-icon>
            </v-btn>
          </template>
          <v-card height="400">
            <v-card-text style="background: var(--v-background-base)">
              <json-tree :raw="JSON.stringify(item.fullOrder)" :level="1" />
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </page-component>
  </div>
</template>

<script>
// Components
import PageComponent from "../../shared/PageComponent.vue"
import VueJsonToCsv from "vue-json-to-csv"
import CustomDateRangeFilter from "../../shared/forms/datetime/CustomDateRangeFilter.vue"
import CustomSelect from "../../shared/CustomSelect.vue"
import OrderDetails from "./OrderDetails.vue"
// Mixins
import getOrderRefunds from "../../Sales/mixins/getOrderRefunds.js"
import getOrderHeaders from "./headers.js"
import getOrderData from "./items.js"
import formatPrice from "@/mixins/formatPrice.js"
// Libraries
import { mapGetters, mapState, mapActions } from "vuex"
import JsonTree from "vue-json-tree"
import moment from "moment"

export default {
  name: "sales-report",
  components: {
    PageComponent,
    VueJsonToCsv,
    CustomDateRangeFilter,
    CustomSelect,
    OrderDetails,
    JsonTree,
  },
  mixins: [getOrderRefunds, getOrderHeaders, getOrderData, formatPrice],
  data() {
    return {
      loading: false,
      dates: [
        moment().subtract(7, "d").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      selectedRestaurantIds: [],
      orderStatusFilter: ["open", "completed"],
      orderSourceFilter: [],
      typeFilter: [],
      includeGeneralColumns: true,
    }
  },
  computed: {
    ...mapState([
      "allOrders",
      "refunds",
      "currentUserRole",
      "customers",
      "restaurants",
      "firebaseRefs",
      "settings",
    ]),
    ...mapGetters(["getRestaurantName", "isNextGenUser"]),
    type() {
      return this.$route.params.tab
    },
    title() {
      switch (this.type) {
        case "nextgen":
          return "NextGen"
        case "org":
          return this.settings.organizationName + " Commission"
        case "sales":
        default:
          return "Financial"
      }
    },
    orderStatusOptions() {
      return [
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Open",
          value: "open",
        },
        {
          text: "Completed",
          value: "completed",
        },
      ]
    },
    orderSourceOptions() {
      return [
        // {
        //   text: "Kiosk Dine-In",
        //   value: "dineIn",
        // },
        {
          text: "Kiosk Take-Out",
          value: "takeOut",
        },
      ].concat(
        this.appTypes.map(appType => {
          return {
            text: appType.charAt(0).toUpperCase() + appType.slice(1),
            value: appType,
          }
        })
      )
    },
    typeOptions() {
      return [
        {
          text: "Order",
          value: "Order",
        },
        {
          text: "Refund",
          value: "Refund",
        },
      ]
    },
    appTypes() {
      return [
        ...new Set(
          this.allOrders.map(
            order => order.channelData.appType || order.channelData.orderType
          )
        ),
      ].filter(appType => appType && appType !== "kiosk")
    },
    headers() {
      if (this.loading) {
        return [{ text: "", value: "loading" }]
      }
      return this.getHeaders(this.includeGeneralColumns, this.type, {
        selectedRestaurantId: this.selectedRestaurantIds.length
          ? this.selectedRestaurantIds[0]
          : null,
      })
    },
    csvHeaders() {
      const headers = [
        {
          text: "NextGen Order ID",
          value: "id",
        },
      ].concat(this.headers.filter(header => header.value != "actions"))
      return headers.reduce((map, header) => {
        map[header.value] = {
          title: header.text,
        }
        return map
      }, {})
    },
    salesData() {
      let filteredOrders = this.allOrders
      let filteredRefunds = this.refunds
      if (this.dates?.length) {
        filteredOrders = filteredOrders.filter(order => {
          const orderDate = order.orderDate?.toDate()
          return (
            // eslint-disable-next-line prettier/prettier
            moment(orderDate).isSameOrAfter(moment(this.dates[0], this.dateFormat)) &&
            moment(orderDate).isSameOrBefore(moment(this.dates[1], this.dateFormat))
          )
        })

        filteredRefunds = filteredRefunds.filter(refund => {
          const refundDate = refund.refundDate?.toDate()
          return (
            // eslint-disable-next-line prettier/prettier
            moment(refundDate).isSameOrAfter(moment(this.dates[0], this.dateFormat)) &&
            moment(refundDate).isSameOrBefore(moment(this.dates[1], this.dateFormat))
          )
        })
      }
      if (this.selectedRestaurantIds?.length) {
        filteredOrders = filteredOrders.filter(order => {
          return (
            order.restaurants?.length &&
            order.restaurants.some(restaurant => {
              return this.selectedRestaurantIds.includes(restaurant.id)
            })
          )
        })

        filteredRefunds = filteredRefunds.filter(refund => {
          return (
            refund.restaurants?.length &&
            refund.restaurants.some(restaurant => {
              return this.selectedRestaurantIds.includes(restaurant.id)
            })
          )
        })
      } else {
        return []
      }

      filteredOrders = filteredOrders.filter(order => {
        const conditions = []
        if (!this.orderSourceFilter.length || !this.orderStatusFilter) {
          conditions.push(false)
        } else {
          // status filter
          const includedStatuses = []
          if (this.orderStatusFilter.includes("pending")) {
            includedStatuses.push("Pending")
          }
          if (this.orderStatusFilter.includes("open")) {
            includedStatuses.push(
              "Payment Accepted",
              "Accepted",
              "In Progress",
              "Ready For Pickup"
            )
          }
          if (this.orderStatusFilter.includes("completed")) {
            includedStatuses.push("Completed", "Refunded", "Cancelled")
          }
          conditions.push(includedStatuses.includes(order.status))

          // source filter
          if (!this.orderSourceFilter.includes("dineIn")) {
            conditions.push(
              order.channelData.appType !== "kiosk" ||
                order.channelData.orderType !== "dineIn"
            )
          }
          if (!this.orderSourceFilter.includes("takeOut")) {
            conditions.push(
              order.channelData.appType !== "kiosk" ||
                order.channelData.orderType !== "takeOut"
            )
          }
          for (const appType of this.appTypes) {
            if (!this.orderSourceFilter.includes(appType)) {
              conditions.push(order.channelData.appType !== appType)
            }
          }
        }
        return conditions.every(condition => condition)
      })

      filteredRefunds = filteredRefunds.filter(
        refund => refund.status === "Completed"
      )

      filteredOrders.forEach(order => (order.type = "Order"))
      filteredRefunds.forEach(refund => (refund.type = "Refund"))

      const rows = []

      if (this.typeFilter.length) {
        if (this.typeFilter.includes("Order")) {
          rows.push(...filteredOrders)
        }
        if (this.typeFilter.includes("Refund")) {
          rows.push(...filteredRefunds)
        }
      } else {
        rows.push(...filteredOrders, ...filteredRefunds)
      }

      return [
        ...rows
          .map(transaction => {
            if (transaction.type === "Order") {
              return this.getOrderData(transaction)
            }
            return this.getRefundData(transaction)
          })
          .map((order, index) => {
            order.index = index
            return order
          })
          .sort((a, b) => {
            if (a.type === "Order" && b.type === "Order") {
              return moment(b.date, this.dateFormat).diff(
                moment(a.date, this.dateFormat)
              )
            }
          })
          .sort((a, b) => {
            if (a.type === "Order" && b.type === "Refund") {
              if (a.id === b.id) {
                return -1
              }
            } else if (a.type === "Refund" && b.type === "Order") {
              if (a.id === b.id) {
                return 1
              }
            }
            return 0
          })
          .reduce((a, c) => {
            a.set(c.type === "Refund" ? c.refundId : c.id, c)
            return a
          }, new Map())
          .values(),
      ]
    },
    dateFormat() {
      return "YYYY-MM-DD HH:mm:ss"
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      this.selectedRestaurantIds = [this.$route.params.id]
    } else {
      this.selectedRestaurantIds = this.restaurants.map(restaurant => restaurant.id)
    }
    this.loading = true
    let dateRange
    if (this.dates && this.dates.length === 2) {
      dateRange = {
        startDate: new Date(this.dates[0]),
        endDate: new Date(this.dates[1]),
      }
    }

    await this.fetchAllOrders(dateRange)
    await this.fetchRefunds()

    this.orderSourceFilter = this.orderSourceOptions.map(option => option.value)
    this.typeFilter = this.typeOptions.map(option => option.value)
    this.loading = false
  },
  methods: {
    ...mapActions(["fetchRefunds", "fetchAllOrders"]),
    getDateFormatted(date) {
      return moment(date, this.dateFormat).format("YYYY-MM-DD")
    },
    getTimeFormatted(date) {
      return moment(date, this.dateFormat).format("HH:mm:ss")
    },
    getTotal(column) {
      if (
        ["OrderId", "source", "status", "actions", "orderNumber", "date"].includes(
          column
        )
      ) {
        return ``
      }
      let total = 0
      this.salesData.forEach(order => {
        total += parseFloat(order[column] ? order[column] : 0)
      })
      return this.formatPrice(total * 100)
    },
    async datesRangeUpdated(dates) {
      this.loading = true
      let dateRange
      if (dates && dates.length === 2) {
        dateRange = {
          startDate: new Date(dates[0]),
          endDate: new Date(dates[1]),
        }
      }
      await this.fetchAllOrders(dateRange)
      await this.fetchRefunds()
      this.dates = dates
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.page-container .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.page-container .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  @media screen and (min-width: 1500px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
</style>
